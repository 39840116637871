// jshint esversion:6

export const Validation = {
  addError: (input, message) => {
    if(input.substr(-5) === "-base") {
      return toastr.error(message);
    }

    const elementId = `help-block-${input}`;
    const helpBlock = document.getElementById(elementId);
    if(helpBlock) {
      const errorText = helpBlock.getElementsByTagName('p')[0];
      helpBlock.hidden = false;
      errorText.textContent = message;

      let element;
      if( helpBlock.hasAttribute('data-error-id')) {
        element = document.getElementById(helpBlock.getAttribute('data-error-id'));
      } else {
        element = helpBlock.previousSibling;
      }
      return element.classList.add('invalid');
    }

    const inputNode = document.getElementById(input);
    if (inputNode) {
      return inputNode.classList.add('invalid');
    }

    toastr.error(message);
  },
};

export default Validation;
