// jshint esversion: 6

import Validation from "./validation";
import Mask from "./mask";
import toastr from "toastr";
import Rails from "@rails/ujs";

toastr.options = {
  "closeButton": true,
  "debug": false,
  "progressBar": true,
  "preventDuplicates": true,
  "positionClass": "toast-top-right",
  "onclick": null,
  "showDuration": "400",
  "hideDuration": "1000",
  "timeOut": "7000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
};

window.toastr = toastr;
window.Validation = Validation;
window.Mask = Mask;
window.Rails = Rails;