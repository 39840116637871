// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 

// jshint esversion: 6

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['input'];

  initialize() {
    this.initializeListener();
  }

  // Private methods
  initializeListener() {
    this.inputTargets.forEach((element) => {
      let onEvent = 'keydown';

      if (element.nodeName === 'SELECT') {
        onEvent = 'change'
      }

      element.addEventListener(onEvent, () => {
        if (element.classList.contains('invalid')) {

          element.classList.remove('invalid');
          let helpBlock;
          if (element.hasAttribute('data-error-id')) {
            helpBlock = document.getElementById(element.getAttribute('data-error-id'));
          } else {
            helpBlock = element.nextSibling;
          }
          if (helpBlock.classList.contains('js-help-block')) {
            helpBlock.hidden = true;
          }
        }
      });
    });
  }
}
