// jshint esversion: 6

import moment from 'moment';

const momentFormat = 'MM/DD/YYYY';

export const Mask = {
  addPhoneMask: (id) => {
    IMask(
      document.getElementById(id), {
      mask: '(000)000 00 00'
    });
  },
  addSSNMask: (id) => {
    IMask(
      document.getElementById(id), {
        mask: '000-00-0000'
      });
  },
  addDateMask: (id) => {
    const element = document.getElementById(id);
    const maxYear = new Date().getFullYear();
    IMask(element, {
      mask: Date,
      pattern: momentFormat,
      min: new Date(1910, 0, 1),
      max: new Date(maxYear, 0, 1),
      autofix: true,

      format: function (date) {
        return moment(date).format(momentFormat);
      },
      parse: function (str) {
        return moment(str, momentFormat);
      },

      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1910,
          to: maxYear
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31
        }
      }
    });
  },
};

export default Mask;