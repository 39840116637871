// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 

// jshint esversion: 6

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['input'];

  initialize(){
    this.initializeListener();
  }

  initializeListener() {
    this.inputTargets.forEach((element) => {
      element.addEventListener('change', () => {
        const fileName = element.value.split('\\').pop();
        const textBlock = document.getElementById(element.id.replace('form_', ''));
        textBlock.innerText = fileName;
      });
    });
  }
}
